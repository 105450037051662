<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-11 14:28:35
 * @LastEditors: ------
 * @LastEditTime: 2021-08-04 10:17:05
-->
<template>
  <a-locale-provider :locale="locale">
    <div v-if="navShow">
      <Header></Header>
    </div>
    <!-- <transition :name="transitionRouter">
      <router-view />
    </transition> -->
    <router-view v-slot="{ Component }">
      <transition :name="transitionRouter">
        <keep-alive v-if="$route.meta.keepAlive">
          <component :is="Component" />
        </keep-alive>
      </transition>
      <transition :name="transitionRouter">
        <keep-alive v-if="!$route.meta.keepAlive">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <Footer></Footer>
    <Loading v-if="$store.state.loadingShow" />
  </a-locale-provider>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "@/components/loading/loading.vue"; //请求加载提示
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  data() {
    return {
      navShow: true,
      locale: zhCN,
      transitionRouter: "",
      // openedTabs: ["about"],
    };
  },
  created() {},
  mounted() {},
  methods: {},

  components: {
    Header,
    Footer,
    Loading,
  },

  watch: {
    $route(to, from) {
      if (to.path == "/") {
        this.navShow = false;
      } else {
        this.navShow = true;
      }
      if (to.meta.index > from.meta.index) {
        this.transitionRouter = "slide-left";
      } else {
        this.transitionRouter = "slide-right";
      }
    },
  },
};
</script>
<style>
@import "./style/index.css";
@import "~ant-design-vue/dist/antd.css";

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  /* color: #2c3e50; */
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.5s;
  /* width: 100%; */
  left: 0;
}
.slide-right-enter-from {
  transform: translateX(-100%);
}
.slide-right-leave-active {
  transform: translateX(100%);
}
.slide-left-enter-from {
  transform: translateX(100%);
}
.slide-left-leave-active {
  transform: translateX(-100%);
}
</style>
