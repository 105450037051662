<template>
  <div class="login" @keydown="enter">
    <div class="nav">
      <div class="logo">
        <img src="../image/login_logo.png" alt="首页" />
      </div>
      <div class="input">
        <div class="input_icon">
          <img src="../image/name.png" alt="" /><input
            type="text"
            placeholder="请输入账号"
            v-model="name"
            ref="name"
          />
        </div>
        <div class="input_icon">
          <img src="../image/password.png" alt="" /><input
            :type="isShow ? 'text' : 'password'"
            placeholder="请输入密码"
            v-model="password"
            ref="password"
          />
          <img
            v-if="!isShow"
            class="isShow cursor"
            src="../image/closeEye.png"
            alt=""
            @click="btnIsShow()"
          />
          <img
            v-else
            class="isShow cursor"
            src="../image/openEye.png"
            alt=""
            @click="btnIsShow()"
          />
        </div>
        <div class="input_icon">
          <img src="../image/code.png" alt="" />
          <input
            type="text"
            placeholder="请输入验证码"
            v-model="isCode"
            ref="code"
          />
          <div class="btn_code">
            <!-- <img class="real-name" src="../image/real-name.png" alt="" /> -->
            <canvas
              id="canvas"
              :width="canvas_width"
              :height="canvas_height"
            ></canvas>
            <div class="base_color cursor" @click="showNumber">换一张</div>
          </div>
        </div>
        <div class="login_box">
          <button
            class="login_btn cursor"
            @click="goLogin"
            :disabled="disabled"
            ref="loginBtn"
          >
            登&nbsp;&nbsp;录
          </button>
          <div class="base_color forget cursor font_weight_400">
            忘记密码?
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, getCode } from "../api/api";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      name: "",
      password: "",
      isCode: "",
      key: "",
      code: "", // 数字验证码
      canvas_width: "100",
      canvas_height: "26",
      disabled: false,
      isShow: false,
    };
  },

  created() {
    this.showNumber();
  },
  methods: {
    enter(e) {
      //输入内容完成时监听是否按下 enter
      if (e.keyCode == 13) {
        this.goLogin();
      }
    },
    btnIsShow() {
      this.isShow = !this.isShow;
    },
    // 登录
    async goLogin() {
      let that = this;

      // 判断账号是否正确
      if (that.name == "") {
        that.$refs.name.style.border = "1px solid #fe892e";
        that.$refs.name.placeholder = "账号不能为空";
        return false;
      }
      // 判断密码是否正确
      if (that.password == "") {
        that.$refs.password.style.border = "1px solid #fe892e";
        that.$refs.password.placeholder = "密码不能为空";
        return false;
      }
      // 判断验证码是否正确
      if (that.isCode == "") {
        that.$refs.code.style.border = "1px solid #fe892e";
        that.$refs.code.placeholder = "验证码不能为空";
        return false;
      }
      that.$refs.code.style.border = "";
      that.$refs.password.style.border = "";
      that.$refs.name.style.border = "";
      try {
        let res = await login({
          username: that.name,
          password: that.password,
          captcha: that.isCode,
          checkKey: that.key,
        });
        console.log(
          res,
          that.name,
          that.password,
          that.isCode,
          that.key,
          "登录成功返回信息------------------"
        );
        if (res.status == 200) {
          if (res.data.code == 200) {
            that.$store.commit("setUser", res.data.result.token);
            that.$store.commit("setUserInfo", res.data.result.userInfo);
            that.$router.push("/home");

            // that.$store.commit("setNewTime", new Date().valueOf());
          } else if (res.data.code == 500) {
            that.$message.warning(res.data.message);
          }
        }
      } catch (error) {
        that.$message.error("登录失败!!!");
      }
    },

    // 获得验证码
    async showNumber() {
      try {
        let that = this;
        let res = await getCode(); // es6 解构方式 直接获取到数据
        if (res.data.success == true) {
          that.code = res.data.result.code;
          that.key = res.data.result.key;
          that.drawCode();
        }
      } catch (error) {
        // alert("验证码获取错误!");
        this.$message.warning("获取验证码失败!!!");
        this.disabled = true;
        this.$refs.loginBtn.style.opacity = "0.5";
      }
    },
    // 画数字
    drawCode() {
      let canvas = document.getElementById("canvas"); //获取到canvas的对象，演员
      let context = canvas.getContext("2d"); //获取到canvas画图的环境，演员表演的舞台

      // console.log(context);
      context.fillStyle = "#fff";
      context.fillRect(0, 0, 300, 200);

      for (let i = 0; i < this.code.length; i++) {
        let deg = (Math.random() * 15 * Math.PI) / 180; //产生0~30之间的随机弧度

        let x = 4 + i * 25; //文字在canvas上的x坐标
        let y = 16 + Math.random() * 8; //文字在canvas上的y坐标
        context.font = "bold 20px 微软雅黑";
        context.translate(x, y);
        context.rotate(deg);
        context.fillStyle = this.randomColor();
        context.fillText(this.code[i], 0, 0);
        context.rotate(-deg);
        context.translate(-x, -y);
      }
      for (let i = 0; i <= 5; i++) {
        //验证码上显示线条
        context.strokeStyle = this.randomColor();
        context.beginPath();
        context.moveTo(
          Math.random() * this.canvas_width,
          Math.random() * this.canvas_height
        );
        context.lineTo(
          Math.random() * this.canvas_width,
          Math.random() * this.canvas_height
        );
        context.stroke();
      }
      for (let i = 0; i <= 30; i++) {
        //验证码上显示小点
        context.strokeStyle = this.randomColor();
        context.beginPath();
        let x = Math.random() * this.canvas_width;
        let y = Math.random() * this.canvas_height;
        context.moveTo(x, y);
        context.lineTo(x + 1, y + 1);
        context.stroke();
      }
    },

    /**
     * @description:得到随机颜色
     */
    randomColor() {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
  },
};
</script>

<style scoped>
.login {
  background: url("../image/login_bg.png") no-repeat;
  background-size: 100% 1080px;
  width: 100%;
  height: 964px;
  margin: auto;
}
.nav {
  font-weight: bold;
  color: #2c3e50;
  width: 450px;
  position: absolute;
  top: 240px;
  left: 448px;
}

.logo {
  text-align: left;
}
.logo img {
  width: 348px;
  height: 66px;
}
.input {
  margin-top: 66px;
}
.input_icon input {
  width: 100%;
  height: 66px;
  background: #fcfcfe;
  box-shadow: 0px 0px 23px 0px rgba(214, 214, 248, 0.54);
  border-radius: 6px;
  border: 1px solid #dddddd;
  line-height: normal;
  font-size: 20px;
  padding-left: 66px;
}
::-webkit-input-placeholder {
  color: #cacaca;
  font-size: 20px;
}

::-moz-placeholder {
  color: #cacaca;
  font-size: 20px;
}
:-ms-input-placeholder {
  color: #cacaca;
  font-size: 20px;
}
input:focus {
  border-radius: 6px;
  border: 1px solid #acacac;
}
.input_icon {
  position: relative;
  margin-bottom: 20px;
}
.input_icon img {
  position: absolute;
  top: 22px;
  left: 24px;
  width: 18px;
  height: 20px;
}
.input_icon .btn_code {
  /* text-align: right; */
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
}
.input_icon .btn_code #canvas {
  width: 70px;
  height: 28px;
  margin-right: 20px;
}

.login_box {
  display: flex;
  justify-content: space-between;
  padding-top: 18px;
}
.login_btn {
  width: 232px;
  height: 68px;
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 6px;
  color: #fffffe;
  line-height: 68px;
  font-size: 20px;
}
.forget {
  width: 210px;
  height: 68px;
  line-height: 68px;
  font-size: 18px;
}
.input_icon .isShow {
  margin-left: 370px;
  width: 26px;
  height: 20px;
}
</style>
