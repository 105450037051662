/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-08-06 15:32:44
 */
/**
 *     api 请求模块
 */
import request from "@/utils/request";

/**
 * 用户登录 POST
 */
export const login = (data) => {
  return request({
    method: "POST",
    url: "sales/login",
    // data 用来设置 POST 请求体
    data,
  });
};

//  获取验证码 接口 get
export const getCode = () => {
  return request({
    method: "GET",
    url: "sys/getCheckCode",
  });
};
/**
 *   GET  获取护理员列表(包含个人信息)
 */
export const getNurseDetail = (params) => {
  return request({
    method: "GET",
    url: "/weixin/nurse/mp/list",
    params,
  });
};

//  客户管理列表
export const getCustomerList = (params) => {
  return request({
    method: "GET",
    url: "/sales/account/list",
    params,
  });
};

//  护理员个人信息详情ID
export const getNurseDetailID = (params) => {
  return request({
    method: "GET",
    url: "/weixin/nurse/mp/queryById",
    params,
  });
};

//  可模糊查询已有护理员
export const getNurseFind = (params) => {
  return request({
    method: "GET",
    url: "/weixin/account/key-list",
    params,
  });
};

//  根据查询已有护理员,,来查找服务对象姓名
export const getServeName = (params) => {
  return request({
    method: "GET",
    url: "/weixin/account/queryFamilyMemberSelectList",
    params,
  });
};

//  根据所选城市 ID , 获取服务类型
export const getServeTypeCon = (params) => {
  return request({
    method: "GET",
    url: "/service/service/select-list",
    params,
  });
};

//  生成服务合同
export const postContract = (data) => {
  return request({
    method: "POST",
    url: "/order/order/unified-order",
    data,
  });
};
//  创建客户信息
export const consumerInfo = (data) => {
  return request({
    method: "POST",
    url: "/consumer/consumer/add-account/",
    data,
  });
};

//加载门店选择列表
export const getLoad = (params) => {
  return request({
    method: "GET",
    url: "/ajh/store/load-data",
    params,
  });
};
//操作--查看
export const getLookInfo = (params) => {
  return request({
    method: "GET",
    url: "/consumer/consumer/queryByAccountId",
    params,
  });
};
//操作--查看 - 亲属
export const getLookInfoClan = (params) => {
  return request({
    method: "GET",
    url: "/weixin/account/queryAccountFamilyMemberByMainId?_t=1627371396",
    params,
  });
};

//操作--查看 - 所在城市
export const getLookInfoCity = (params) => {
  return request({
    method: "GET",
    url: "/location/divisions/queryByAccountId",
    params,
  });
};
//操作--查看 - 合同列表
export const getClanInfoCon = (params) => {
  return request({
    method: "GET",
    url: "/order/order/listByAccountId",
    params,
  });
};
//操作--查看 - 合同详情
export const getClanDetail = (params) => {
  return request({
    method: "GET",
    url: "/order/order/" + params,
  });
};
//操作--支付 - 支付列表
export const getPayList = (params) => {
  return request({
    method: "GET",
    url: "/order/pay/queryByAccountId",
    params,
  });
};
//操作--支付 - 支付二维码
export const PostPay = (data) => {
  return request({
    method: "POST",
    url: "/weixin/payparams/queryWxUrl",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//操作--支付--补录
export const PostPaySupplement = (data) => {
  return request({
    method: "POST",
    url: "/order/pay/detail/add",
    data,
  });
};
//操作--访谈--列表
export const getTalkList = (params) => {
  return request({
    method: "GET",
    url: "/sales/account/interview/list",
    params,
  });
};

///操作--查看 生成意向单
export const addInterview = (data) => {
  return request({
    method: "POST",
    url: "/sales/account/interview/add",
    data,
  });
};
///操作--查看 根据 ID 获取意向单
export const getInterviewId = (params) => {
  return request({
    method: "GET",
    url: "/sales/account/interview/queryById",
    params,
  });
};
///操作--查看 获取意向单列表
export const gettalkList = (params) => {
  return request({
    method: "GET",
    url: "/sales/account/interview/list",
    params,
  });
};

// 加载访谈服务类型选择
export const getType = (params) => {
  return request({
    method: "GET",
    url: "/sys/dict/getDictItems/nurse_type?_t=1627371396",
    params,
  });
};
