/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-08-13 16:20:54
 */
import { createApp } from "vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import Antd from "ant-design-vue";

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(Antd)
  .component(VueQrcode.name, VueQrcode)
  .mount("#app");
