<template>
  <div class="loader">
    <div class="loading" id="loading">
      <div class="animateWrap">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="text">请稍候...</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
  mounted() {
    var spanli = document
      .getElementById("loading")
      .getElementsByTagName("span");
    for (var i = 0; i < spanli.length; i++) {
      spanli[i].style.webkitAnimationDelay = 0.13 * i + "s";
    }
  },
};
</script>
<style scoped>
.loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999999999999990;
}
.loading {
  position: absolute;
  width: 170px;
  height: 70px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.loading span {
  display: inline-block;
  width: 8px;
  height: 60px;
  border-radius: 3px;
  margin: 6px;
  background: #5af3f3;
  -webkit-animation: line-square 1s infinite;
}
.text {
  color: #5af3f3;
  font-size: 30px;
}
@-webkit-keyframes line-square {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.3);
  }
  100% {
    transform: scaleY(1);
  }
}
</style>
