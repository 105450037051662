<template>
  <div class="navbar">
    <img src="../image/AJH-LOGO.png" alt="" class="logo" />
    <div class="interval"></div>
    <div class="title">爱介护居家养老服务平台</div>
    <img :src="info.avatar" v-if="info.avatar" alt="" class="portrait" />
    <img src="../image/touxiang.png" v-else class="portrait" />
    <div class="name">{{ info.realname }}</div>
    <img src="../image/shuaxin.png" class="icon1" @click="go" />
    <a-button type="" @click="go" class="buton">刷新</a-button>

    <div class="interval1"></div>
    <img src="../image/esc.png" class="icon" @click="exit" />
    <a-button type="" @click="exit" class="buton">退出</a-button>
  </div>
</template>

<script>
import { getItem } from "../utils/storage";

export default {
  name: "NavBar",
  props: {},
  data() {
    return {
      info: null,
    };
  },
  created() {
    this.info = getItem("userInfo");
  },
  methods: {
    go() {
      this.$router.go(0);
    },
    exit() {
      localStorage.clear();
      this.$router.push({ path: "/", replace: true });
    },
  },
};
</script>
<style scoped>
.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 16px 0px rgba(205, 210, 243, 0.4);
}
.logo {
  width: 140px;
  height: 70px;
  margin-left: 42px;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-right: 14px;
  text-align: left;
}
.interval {
  width: 1px;
  height: 55px;
  background: #d3d3d3;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: 28px;
}
.title {
  height: 22px;
  font-size: 22px;
  color: #333333;
  margin-left: 25px;
  margin-top: 39px;
  margin-bottom: 37px;
}
.portrait {
  width: 70px;
  height: 70px;
  margin-left: 943px;
  margin-top: 14px;
  margin-bottom: 12px;
  border-radius: 50%;
}
.name {
  height: 22px;
  font-size: 22px;
  margin-left: 19px;
  margin-top: 38px;
  margin-bottom: 38px;
}
.icon1 {
  width: 22px;
  height: 22px;
  margin-left: 49px;
  margin-top: 42px;
}
.icon {
  width: 22px;
  height: 22px;
  margin-left: 20px;
  margin-top: 42px;
}
.interval1 {
  width: 1px;
  height: 55px;
  background: #d3d3d3;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: 14px;
}
.buton {
  width: 60px;
  height: 20px;
  border: none;
  font-size: 20px;
  margin-top: 38px;
  margin-right: 5px;
  margin-left: 2px;
  color: #999999;
}
</style>
