<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-07-09 17:28:06
-->
<template>
  <div class="footer">
    <div>
      <div class="adreess">上海爱介护社区养老服务有限公司</div>
      <div>沪ICP备16027030号-1</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>
<style scoped>
.footer {
  width: 100%;
  height: 116px;
  background: #5d6571;
  /* position: relative;
  bottom: 0; */
  color: #cacaca;
  text-align: center;
  z-index: 111;
}
.footer > div {
  padding-top: 30px;
  font-size: 18px;
  height: 116px;
}
.adreess {
  margin-bottom: 4px;
}
</style>
