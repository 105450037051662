/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-08-03 16:36:30
 */
import { createStore } from "vuex";
import { getItem, setItem } from "@/utils/storage";
const USER_KEY = "user"; //自定义的存储名

export default createStore({
  state: {
    // 用户的登录状态信息
    // user: JSON.parse(window.localStorage.getItem("KEY")),
    user: getItem(USER_KEY) || false, // 这里的 user 为后台请求的 带有token 字段的信息
    loadingShow: false,
    userInfo: getItem("userInfo"),
    // newTime: getItem("newTime"), //最新一次请求时间
    contract: getItem("contract"),
    typeNum: getItem("typeNum"),
    serveInfo: getItem("serveInfo"),
    consumer: getItem("consumer"),
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      // window.localStorage.setItem("KEY", JSON.stringify(user));
      setItem(USER_KEY, state.user);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      setItem("userInfo", state.userInfo);
    },
    setContract(state, contract) {
      state.contract = contract;
      setItem("contract", state.contract);
    },
    setServeInfo(state, serveInfo) {
      state.serveInfo = serveInfo;
      setItem("serveInfo", state.serveInfo);
    },
    setTypeNum(state, typeNum) {
      state.typeNum = typeNum;
      setItem("typeNum", state.typeNum);
    },
    setConsumer(state, consumer) {
      state.consumer = consumer;
      setItem("consumer", state.consumer);
    },
  },
  actions: {},
  modules: {},
});
