/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-08-17 17:19:42
 */
//  axios 请求配置------------------

import axios from "axios";
import store from "@/store/";
import router from "@/router";

// axios.create 方法   复制一个axios
const request = axios.create({
  // baseURL: "http://192.168.0.238:6102/gateway-ext/", //马哥----------------------- 暂未填写
  // baseURL: "http://192.168.0.112:6102/gateway-ext/", //小鑫----------------------- 暂未填写
  baseURL: "https://api-test.aijiehu.cn/gateway-ext/", //测试1----------------------- 暂未填写
  // baseURL: "https://api.aijiehu.cn/gateway-ext/", //正式----------------------- 暂未填写
  // baseURL: "https://m4.aijiehu.cn/gateway-ext/", //测试2----------------------- 暂未填写
  timeout: 5000, //请求超时
});
// request.defaults.headers.post["Content-Type"] =
//   "application/json;charset=UTF-8";

// 请求拦截器
request.interceptors.request.use(
  function(config) {
    store.state.loadingShow = true; // 控制 loading 显示

    if (store.state.user) {
      // const user = store.state.user;
      const user = localStorage.getItem("user");
      config.headers.['X-Access-Token'] = user;
    }
    return config;
  },
  function (error) {
    store.state.loadingShow = false; // 控制 loading 隐藏
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  // 响应成功进入第一个函数,参数是响应对象
  (response) => {
    setTimeout(() => {
      store.state.loadingShow = false; // 控制 loading 隐藏
    }, 400);
    return response;
  },
  // 响应失败进入第二个函数,参数是错误对象
  (error) => {
    console.log(error.response,'9999999999999');
    store.state.loadingShow = false; // 控制 loading 隐藏

    if (error.response.status == 401 || error.response.status == 500) {
      console.log(error.response.status);
      return redirectLogin();
    }

    return Promise.reject(error);
  }
);
function redirectLogin() {
  router.replace("/");
  localStorage.clear();
  
}
export default request;
