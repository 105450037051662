/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2021-06-09 17:50:54
 * @LastEditors: ------
 * @LastEditTime: 2021-08-13 16:25:37
 */
import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "Login", //登录
    component: Login,
    meta: { index: 0, title: "爱介护-销售管理系统" },
  },
  {
    path: "",
    name: "Login", //登录
    component: () => import("@/views/Login.vue"),
    meta: { index: 0, title: "爱介护-销售管理系统" },
  },
  {
    path: "/home",
    name: "Home", //首页
    component: () => import("@/views/Home.vue"),
    meta: {
      index: 0,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: false,
      title: "爱介护-首页",
    },
  },
  {
    path: "/chooseServe",
    name: "ChooseServe", //选择服务
    component: () => import("@/views/ChooseServe.vue"),
    meta: {
      index: 3,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-选择服务",
    },
  },
  {
    path: "/nurseList",
    name: "NurseList", //服务列表
    component: () => import("@/views/NurseList.vue"),
    requireAuth: true,
    meta: {
      index: 2,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-选择服务列表",
    },
  },
  {
    path: "/nurseDetail",
    name: "NurseDetail", //服务详情
    component: () => import("@/views/NurseDetail.vue"),
    meta: {
      index: 3,
      requireAuth: true,
      title: "爱介护-服务详情",
    },
  },
  {
    path: "/serveManage",
    name: "ServeManage", //服务合同管理
    component: () => import("@/views/ServeManage.vue"),
    meta: {
      index: 1,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-服务合同管理",
    },
  },
  {
    path: "/customerList",
    name: "CustomerList", //客户列表
    component: () => import("@/views/CustomerList.vue"),
    meta: {
      index: 3,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-客户列表",
    },
  },
  {
    path: "/serveDetail",
    name: "ServeDetail", //服务详情
    component: () => import("@/views/ServeDetail.vue"),
    meta: {
      index: 2,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-服务详情",
    },
  },
  {
    path: "/payment",
    name: "Payment", //支付方式
    component: () => import("@/views/Payment.vue"),
    meta: {
      index: 4,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-支付方式",
    },
  },
  {
    path: "/personal",
    name: "Personal", //支付方式
    component: () => import("@/views/Personal.vue"),
    meta: {
      index: 5,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-客户详情",
    },
  },
  {
    path: "/infoOne",
    name: "InfoOne", //支付方式
    component: () => import("@/views/InfoOne.vue"),
    meta: {
      index: 5,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-创建客户信息",
    },
  },
  {
    path: "/infoTwo",
    name: "InfoTwo", //支付方式
    component: () => import("@/views/InfoTwo.vue"),
    meta: {
      index: 6,
      requireAuth: true,
      keepAlive: true,
      title: "爱介护-创建客户信息",
    },
  },
  {
    path: "/lookinfo",
    name: "LookInfo", //查看--操作
    component: () => import("@/views/LookInfo.vue"),
    meta: {
      index: 7,
      requireAuth: true,
      keepAlive: false,
      title: "爱介护-查看详情",
    },
  },
  {
    path: "/find",
    name: "find", //支付方式
    component: () => import("@/views/find.vue"),
    meta: {
      index: 6,
      requireAuth: true,
      keepAlive: false,
      title: "爱介护-创建客户信息",
    },
  },
  {
    path: "/paydetail",
    name: "Paydetail", //支付详情
    component: () => import("@/views/PayDetail.vue"),
    meta: {
      index: 7,
      requireAuth: true,
      keepAlive: false,
      title: "爱介护-支付详情",
    },
  },
  {
    path: "/record",
    name: "Record", //支付详情
    component: () => import("@/views/Record.vue"),
    meta: {
      index: 8,
      requireAuth: true,
      keepAlive: false,
      title: "爱介护-查看更多",
    },
  },
  {
    path: "/interview",
    name: "interview", //添加访谈
    component: () => import("@/views/interview.vue"),
    meta: {
      index: 2,
      requireAuth: true,
      keepAlive: false,
      title: "爱介护-添加访谈",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to) => {
  // if (store.state.user) {
  //   if (to.fullPath == "/") {
  //     return router.push("/home");
  //   }
  // }

  document.title = to.meta.title;
  if (to.meta.requireAuth) {
    if (!store.state.user) {
      return router.push("/");
    }
  } else {
    return true;
  }

  // if (to.meta.requireAuth && !store.state.user) {
  //   //判断是否需要登录
  //   return router.push("/");
  // } else {
  //   return true;
  // }
  //   if (store.state.user) {
  //     // 判断是否有 token
  //     return true;
  //   } else {
  //     return router.push("/");
  //   }
  // } else {
  //   // next();  //这句勿动!!!!-----------------!~~~~~~~~~~~~~~~~~
  //   return true;
  // }
});

export default router;
